import { CircularProgress, Grid, Switch, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { CustomText, Input, InputAutocomplete } from "../../../components";
import CustomTable from "../../../components/others/Table";
import { fonts, Spacing } from "../../../config";
import { api } from "../../../services";
import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBlock: theme.spacing(1.5),
  },
  switchBase: {
    padding: 2.5,
    color: "#00000054",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#00796D",
      "& + $track": {
        backgroundColor: "#C4E8E4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#C4E8E4",
      border: "none",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#EBEBEB",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))((props: any) => {
  const { classes, ...switchProps } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function AddressFieldsExtra({
  formRef,
  setStateSelected,
  setCities,
  states,
  formValue,
  cities,
  setFormValue,
  countries,
  stateSelected,
  copy,
  setCopy,
  extraLoading,
  copyData,

  setInternationalAddress,
  internationalAddress,
  setExtraLoading,
  onBlurSubmit,
}: any) {
  const { loading, call, falseLoading } = hooks.useRequest();

  const loadCities = async (stateId: string | null): Promise<any[]> => {
    if (stateId === null) {
      setStateSelected(false);
      setCities([]);
      return [];
    }

    return new Promise<any[]>(async (resolve, reject) => {
      await call(null, api.getCities(stateId), async (response) => {
        const formData = await formRef?.getValues();

        if (response.ok) {
          setStateSelected(true);
          const citiesList = response?.data?.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          setCities(citiesList);
          setFormValue((v) => {
            return { ...v, ...formData, stateExtra: stateId };
          });
          resolve(citiesList);
        } else {
          reject([]);
        }
      });
    });
  };

  const showCepError = (data: any) => {
    formRef?.formFieldsHandler?.formFields?.postal_codeExtra?.errorHandler(
      "Dados do CEP nao encontrados"
    );
  };

  const getAddressByCep = async (cep: string) => {
    const hasError = validations.isValidCEP(cep);
    formRef?.formFieldsHandler?.formFields?.postal_codeExtra?.errorHandler(
      null
    );
    if (!hasError && !Boolean(copy)) {
      await call(
        null,
        api.getAddressCep(cep),
        async (response) => {
          if (response.ok) {
            let newStateId = await states.find(
              (a) => a.fullName === response?.data?.state
            );

            if (newStateId) {
              let holdCitiesList = await loadCities(newStateId.value);

              let newData = {
                ...formValue,

                numberExtra: response?.data?.number,
                streetExtra: response?.data?.street,
                complementExtra: response?.data?.complement,
                districtExtra: response?.data?.district,
                cityExtra: await holdCitiesList?.find((item) => {
                  if (
                    item?.label === response?.data?.city ||
                    item?.value === response?.data?.city
                  ) {
                    return item;
                  }
                })?.value,
                stateExtra: newStateId?.value,
                countryExtra: response?.data?.country,
                postal_codeExtra: cep,
              };

              setFormValue(newData);
              onBlurSubmit();
            }
          }
        },
        showCepError
      );
    }
  };

  const getSameAddress = async (same) => {
    const formData = await formRef?.getValues();

    if (same) {
      let holdCitiesList;
      if (formData?.state && !internationalAddress?.address) {
        holdCitiesList = await loadCities(formData?.state);
      } else {
        holdCitiesList = await loadCities(null);
        falseLoading();
      }

      await setFormValue((old) => {
        const cityExtra = !internationalAddress?.address
          ? holdCitiesList?.find((item) => {
              if (
                item?.label === formData?.city ||
                item?.value === formData?.city
              ) {
                return item;
              }
            })?.value
          : formData?.city;

        let newData = {
          ...old,
          ...formData,
          stayType: formData?.stayType,
          numberExtra: formData?.number,
          streetExtra: formData?.street,
          complementExtra: formData?.complement,
          districtExtra: formData?.district,
          cityExtra: cityExtra,
          stateExtra: formData?.state,
          countryExtra: formData?.country,
          postal_codeExtra: formData?.postal_code,
        };
     
        return newData;
      });
      onBlurSubmit();
    } else {
      formRef?.clearSpecificFields(
        [
          "numberExtra",
          "streetExtra",
          "complementExtra",
          "districtExtra",
          "cityExtra",
          "stateExtra",
          "countryExtra",
          "postal_codeExtra",
        ],
        false
      );

      setFormValue((old) => {
        let newDataClear = {
          ...old,
          ...formData,
          stayType: old?.stayType,
          numberExtra: null,
          streetExtra: null,
          complementExtra: null,
          districtExtra: null,
          cityExtra: null,
          stateExtra: null,
          countryExtra: null,
          postal_codeExtra: null,
        };

        return newDataClear;
      });
      onBlurSubmit();
    }
  };

  const resetAddress = async () => {
    const formData = await formRef?.getValues();

    formRef?.clearSpecificFields(
      [
        "numberExtra",
        "streetExtra",
        "complementExtra",
        "districtExtra",
        "cityExtra",
        "stateExtra",
        "countryExtra",
        "postal_codeExtra",
      ],
      false
    );

    setFormValue((old) => {
      let newDataClear = {
        ...old,
        ...formData,
        stayType: old?.stayType,
        numberExtra: null,
        streetExtra: null,
        complementExtra: null,
        districtExtra: null,
        cityExtra: null,
        stateExtra: null,
        countryExtra: null,
        postal_codeExtra: null,
      };
      return newDataClear;
    });
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomText
          textColor="#00796D"
          fontFamily={fonts.semibold}
          fontSize={2}
          style={{ paddingBottom: "14px" }}
        >
          Endereço Acompanhante
        </CustomText>
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Styles.TitleInput>
            Mesmo endereço do Hóspede Principal?
          </Styles.TitleInput>

          <IOSSwitch
            disabled={copyData || loading || extraLoading}
            checked={copy}
            onChange={(e) => {
              setCopy(e.target.checked);
              getSameAddress(e.target.checked);

              setInternationalAddress((old) => {
                return { ...old, extraAddress: old?.address };
              });
            }}
            name="checkedC"
          />
        </div>
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Styles.TitleInput>Endereço Internacional?</Styles.TitleInput>

          <IOSSwitch
            disabled={copy || loading || extraLoading}
            checked={internationalAddress?.extraAddress}
            onChange={(e) => {
              !copy &&
                setInternationalAddress((old) => {
                  return { ...old, extraAddress: e.target.checked };
                });

              !copy && resetAddress();
            }}
            name="checkedInternational"
          />
        </div>
      </Grid>
      {internationalAddress?.extraAddress ? (
        <>
          <Grid
            style={{ display: "flex", alignItems: "flex-end" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Input
              key="input-1-postal"
              readOnly={copy}
              required="*Campo Obrigatório"
              name="postal_codeExtra"
              label="Código Postal*"
              maskToSubmit={masks.removeNumberMask}
              customBlur={onBlurSubmit}
            />
          </Grid>
          {loading || extraLoading ? (
            <>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
                sm={8}
                md={8}
                lg={10}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <InputAutocomplete
                  key="input-1-county"
                  validation={validations.validateAutocomplete}
                  name="countryExtra"
                  placeholder="Selecionar"
                  options={countries ? countries : []}
                  label="País*"
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  defaultValue={formValue ? formValue?.countryExtra : -1}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={1}
              >
                <Input
                  name="stateExtra"
                  label="UF*"
                  required="*Campo Obrigatório"
                  readOnly={copy}
                  defaultValue={formValue ? formValue?.stateExtra : null}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <Input
                  readOnly={copy}
                  name="cityExtra"
                  label="Cidade*"
                  required="*Campo Obrigatório"
                  defaultValue={formValue ? formValue?.cityExtra : null}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="districtExtra"
                  label="Bairro*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="streetExtra"
                  label="Logradouro*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={1}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="numberExtra"
                  label="Nº*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="complementExtra"
                  label="Complemento*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid
            style={{ display: "flex", alignItems: "flex-end" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Input
              key="input-2-postal"
              readOnly={copy}
              required="*Campo Obrigatório"
              name="postal_codeExtra"
              label="CEP*"
              mask={masks.CEP}
              validation={validations.isValidCEP}
              maskToSubmit={masks.removeNumberMask}
              customBlur={getAddressByCep}
            />
          </Grid>
          {loading || extraLoading ? (
            <>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
                sm={8}
                md={8}
                lg={10}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <InputAutocomplete
                  key="input-2-county"
                  validation={validations.validateAutocomplete}
                  name="countryExtra"
                  placeholder="Selecionar"
                  options={countries ? countries : []}
                  label="País*"
                  //required="*Campo Obrigatório"
                  defaultValue={formValue ? "BR" : -1}
                  readOnly
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={1}
              >
                <InputAutocomplete
                  validation={validations.validateAutocomplete}
                  name="stateExtra"
                  placeholder="Selecionar"
                  options={states ? states : []}
                  label="UF*"
                  required="*Campo Obrigatório"
                  readOnly={copy}
                  customOnChange={(e, value) => {
                    if (
                      value !== "-1" &&
                      value !== -1 &&
                      value !== null &&
                      !internationalAddress?.extraAddress
                    ) {
                      loadCities(value);
                    }
                  }}
                  defaultValue={formValue ? formValue?.stateExtra : -1}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <InputAutocomplete
                  validation={validations.validateAutocomplete}
                  readOnly={copy}
                  name="cityExtra"
                  options={cities ? cities : []}
                  label="Cidade*"
                  required="*Campo Obrigatório"
                  disabled={!stateSelected}
                  placeholder="Selecionar"
                  defaultValue={formValue ? formValue?.cityExtra : -1}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="districtExtra"
                  label="Bairro*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="streetExtra"
                  label="Logradouro*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={4}
                md={4}
                lg={1}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="numberExtra"
                  label="Nº*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={8}
                md={4}
                lg={2}
              >
                <Input
                  readOnly={copy}
                  required="*Campo Obrigatório"
                  name="complementExtra"
                  label="Complemento*"
                  customBlur={onBlurSubmit}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AddressFieldsExtra;
