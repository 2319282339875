import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../../components";
import BgHeader from "../../../assets/images/bewiki-fachad.webp";
import { Button } from "@material-ui/core";
import { fonts } from "../../../config";
const Container = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
    width: "100%",
    height: "100%",
    maxWidth: "1080px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  };
});

const PageBody = styled.div(({ theme }) => {
  return {
    display: "flex",
    width: "100%",

    flexDirection: "column",

    justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",

    paddingInline: "clamp(24px, 5vw, 100px)",
    backgroundColor: "white",

    backgroundRepeat: "repeat",

    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "0px",
    },
  };
});

const PageHeader = styled.div(({ theme }) => {
  return {
    backgroundImage: `url(${BgHeader})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "center",
    height: "100%",
    width: "100%",
    maxHeight: "320px",
    //padding: "clamp(16px, 2.7vw, 50px)",
    display: "flex",

    position: "relative",
    overflow: "hidden",
  };
});

const BlockHeader = styled.div(({ theme }) => {
  return {
    zIndex: 1,
    height: "100%",
    width: "100%",

    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
});

const ShadowHeader = styled.div(({ theme }) => {
  return {
    zIndex: 0,
    background:
      "transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box",
    position: "absolute",
    height: "100%",
    width: "100%",

    opacity: 0.6,
  };
});

const ButtonSite = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      width: "fit-content",
      [`@media (max-width:${386 - 1}px)`]: {
        width: "100%",
      },
    },
  };
});

const TitleInput = styled.p(({ theme, $withError, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold16,

    padding: 0,
    margin: 0,
    color: $withError ? colors.error.main : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

const Footer = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#000000",
    flexWrap: "wrap",
    paddingInline: "clamp(16px, 7.8vw, 150px)",
    paddingBlock: "clamp(16px, 5.4vw, 105px)",

    justifyContent: "space-between",
    alignItens: "flex-end",

    [theme.breakpoints.down("md")]: { paddingBottom: "80px" },
  };
});

const LeftFooter = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };
});

const RightFooter = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",

    alignItems: "flex-end",
    justifyContent: "flex-end",
  };
});

const BlockContainer = styled.div(({ theme, alternative }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: alternative ? "calc(100% + 100px)" : "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "20px",
    backgroundColor:
      alternative == 1 ? "#00796D" : alternative == 2 ? "white" : "transparent",
    marginInline: alternative && "-50px",

    padding: alternative && "50px",
  };
});

const PaddingContainer = styled.div(({ theme }) => {
  return {
    display: "flex",

    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "50px",
    width: "100%",
    flexDirection: "column",
    gap: "40px",
  };
});

const CardMarket = styled.div(({ theme, $color }) => {
  return {
    cursor: "pointer",
    padding: "32px",
    width: "100%",
    height: "100%",
    display: "flex",
    minHeight: "240px",
    flexDirection: "column",
    backgroundColor: $color,
  };
});

const ImgMarket = styled.img(({ theme, $color }) => {
  return {
    maxWidth: "196px",
    paddingTop: "32px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "clamp(100px,100%,196px)",
    },
  };
});

const ButtonInfo = styled(Button)(({ theme, $color }) => {
  return {
    "&&.MuiButton-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "170px",

      backgroundColor: "white",
      flexDirection: "column",
      gap: "0",
      borderRadius: 0,
      width: "100%",
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "4px",
        textTransform: "capitalize",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  };
});

const ButtonZap = styled(ButtonContained)(({ theme, alternative }) => {
  return {
    "&&.MuiButton-root": {
      backgroundColor: "#000000",
      padding: "10px 15px",
      borderRadius: 0,
      alignSelf: alternative ? "flex-start" : "flex-end",
      marginTop: "18px",
      "& .MuiButton-label": {
        fontSize: "14px",
        fontFamily: fonts.bold,
        textTransform: "capitalize",
      },
    },
    [theme.breakpoints.down("sm")]: {},
  };
});

const CheckInButton = styled(ButtonContained)(({ theme, alternative }) => {
  return {
    "&&.MuiButton-root": {
      backgroundColor: "#000000",
      padding: "10px 20px",
      borderRadius: 0,
      alignSelf: "flex-end",
      marginTop: "0px",

      "& .MuiButton-label": {
        fontSize: "14px",
        fontFamily: fonts.bold,
        textTransform: "capitalize",
        [theme.breakpoints.down("md")]: { fontSize: "12px" },
      },
      [theme.breakpoints.down("md")]: { marginTop: "0px" },
    },
  };
});

const PopUp = styled.div(({ theme, alternative }) => {
  return {
    display: "flex",
    position: "fixed",
    backgroundColor: "#00796D",
    boxShadow: "0px 3px 6px #00000029",
    left: 0,
    bottom: 0,
    width: "100vw",
    height: "fit-content",
    padding: "clamp(12px, 4vw, 18px)",
    zIndex: 99,
    flexDirection: "row",
    alignItens: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: { display: "none" },
  };
});
const PopUpMob = styled.div(({ theme, alternative }) => {
  return {
    display: "none",
    position: "fixed",
    backgroundColor: "#00796D",
    boxShadow: "0px 3px 6px #00000029",
    left: 0,
    bottom: 0,
    width: "100vw",
    height: "fit-content",
    zIndex: 99,
    flexDirection: "row",
    alignItens: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItens: "center",
      justifyContent: "space-between",
      padding: "clamp(16px, 4vw, 28px)",
      flexWrap: "wrap",
    },
  };
});

const Styles = {
  PageBody,
  Container,

  ButtonSite,
  TitleInput,

  Footer,
  LeftFooter,
  RightFooter,

  PageHeader,
  BlockHeader,
  ShadowHeader,
  BlockContainer,
  PaddingContainer,

  CardMarket,
  ImgMarket,
  ButtonInfo,
  ButtonZap,
  CheckInButton,
  PopUp,
  PopUpMob,
};

export default Styles;
