import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";

import styled from "styled-components";
import { FontStyles } from "../index";
import { useFormFull } from "form-full";
import { Spacing } from "../../config";
export type TextInputAutoCompleteBaseProps = {
  label?: string;
  hideVisualError?: boolean;
  smallBottom?: number;
  alternativeColors?: boolean;
  heightInput?: number;
  inputStyle?: styled;
  disabled?: boolean;
  required?: string;
  readOnly?: boolean;
  placeholder?: string;
  justNumbers?: boolean;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  name: string;
  disableError?: boolean;
  white?: boolean;
  defaultValue?: any;
  containerStyles?: any;
  customInput?: any;
  $maxHeight?: any;
  onChangeCustom?: any;
  customBlur?: any;
};

const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 1) : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: "#E6EFEE",
      borderRadius: spacing(1),
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.error.main}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.6 : 1,
      "& .MuiInput-input": {
        backgroundColor: "#E6EFEE",
        color: $alternativeColors ? colors.text.primary : colors.text.primary,

        "&::placeholder": {
          opacity: 0.8,
        },
      },

      ...$inputStyle,
    };
  }
);
const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {};
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    backgroundColor: "#E6EFEE",
    "&.MuiListItem-button:hover": {
      backgroundColor: "#E6EFEE20",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: "#E6EFEE50",
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
  };
});

const StyledSelect = styled(Select)(({ theme, $withError, disabled }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium14,
    color: colors.text.primary,
    padding: spacing(1),
    backgroundColor: "#E6EFEE",
    borderRadius: spacing(0.7),
    opacity: disabled ? 0.6 : 1,
    border: $withError
      ? `1px solid ${colors.background.secondary}`
      : "0px solid red",

    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: spacing(1),
    },
    "& .MuiSelect-icon": {
      color: "#00796D",
    },
  };
});

const Label = styled.p(({ theme, smallLabel, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.semibold16,

    margin: 0,
    marginBottom: spacing(0.5),

    zIndex: $maxHeight ? 1000 : 10,

    color: $withError ? colors.text.tertiary : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

function SelectComponent(props: TextInputAutoCompleteBaseProps) {
  const { value, error, onChange, onBlur, ref, ffHandler } =
    useFormFull.field(props);

  const { name, onChangeCustom, customBlur } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      try {
        onChange(event, event.target.value);
        onChangeCustom && onChangeCustom(event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur(event);
        ffHandler?.testFieldError(name, false);
      }, 10);
    },
    [onChange, onBlur, ffHandler, name, onChange]
  );

  const {
    containerStyles,
    label,
    customInput,
    $maxHeight,
    required,

    options,
    disableError,

    alternativeColors = false,
    disabled,
    readOnly,
  } = props;

  return (
    <StyledFormControl
      style={containerStyles}
      fullWidth
      error={Boolean(error)}
      required={Boolean(required)}
      $maxHeight={$maxHeight}
      onMouseUp={(event) => event.stopPropagation()}
    >
      <Label
        $withValue={Boolean(value) || value === 0}
        $withError={Boolean(error)}
        $maxHeight={$maxHeight}
      >
        {label}
      </Label>
      <StyledSelect
        onBlur={() => {
          customBlur && customBlur();
        }}
        readOnly={readOnly}
        disabled={disabled}
        required={!!required}
        $withError={Boolean(error)}
        $alternativeColors={alternativeColors}
        disableUnderline
        ref={ref}
        value={value}
        $maxHeight={$maxHeight}
        onChange={onSelectValue}
        onMouseUp={(event) => event.stopPropagation()}
        input={
          customInput ? (
            <StyledInput
              readOnly={readOnly}
              disabled={disabled}
              $maxHeight={$maxHeight}
            />
          ) : null
        }
      >
        {options?.map((option) => (
          <StyledMenuItem value={option.value}>{option.label}</StyledMenuItem>
        ))}
      </StyledSelect>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </StyledFormControl>
  );
}

export default SelectComponent;
