import { Grid, Switch, withStyles } from "@material-ui/core";

import {
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
  InputEmail,
  Select,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import { paths } from "../../../navigation/navigate";

import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";

function HeaderHome({ focusView, navigation }: any) {
  return (
    <Styles.ContainerHead>
      <Styles.HeaderContent ref={focusView}>
        <SVG.Behome
          style={{
            width: "120.3px",
            height: "fit-content",
            alignSelf: "center",
            fill: "#00796d",
          }}
        />
        <Styles.LocationContainer>
          <CustomText
            style={{ height: "fit-content" }}
            fontSize={2}
            fontFamily={fonts.semibold}
          >
            Nova Reserva
          </CustomText>
          <CustomText
            style={{ opacity: "0.3", height: "fit-content" }}
            fontSize={3}
            fontFamily={fonts.semibold}
          >
            •
          </CustomText>
          <CustomText
            style={{
              borderBottom: "2px solid #00796D",
              height: "fit-content",
              paddingBlock: "4px",
            }}
            fontSize={2}
            fontFamily={fonts.semibold}
          >
            Editar Ficha de Hóspede
          </CustomText>
        </Styles.LocationContainer>
      </Styles.HeaderContent>

      <Styles.ButtonSite
        onClick={() => {
          navigation(paths.landing + location?.search);
        }}
      >
        Ir para o Portal do Hóspede
      </Styles.ButtonSite>
    </Styles.ContainerHead>
  );
}

export default HeaderHome;
