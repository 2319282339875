import { CustomText } from "../../../components";
import CustomTable from "../../../components/others/Table";
import { fonts, Spacing } from "../../../config";

function Phones() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingInline: Spacing(3.12),
        maxWidth: "820px",
        gap: "8px",
      }}
    >
      <CustomText fontSize={2} fontFamily={fonts.bold}>
        Bombeiros
        <CustomText fontSize={2} fontFamily={fonts.medium}>
          - 193
        </CustomText>
      </CustomText>
      <CustomText
        style={{ paddingBottom: "8px" }}
        fontSize={2}
        fontFamily={fonts.medium}
      >
        Situações de trauma, como quedas, atropelamentos e acidentes
        automobilísticos.
      </CustomText>

      <CustomText
        style={{ paddingBottom: "8px" }}
        fontSize={2}
        fontFamily={fonts.bold}
      >
        Defesa Civil
        <CustomText fontSize={2} fontFamily={fonts.medium}>
          - 199
        </CustomText>
      </CustomText>

      <CustomText
        style={{ paddingBottom: "8px" }}
        fontSize={2}
        fontFamily={fonts.bold}
      >
        Polícia Militar
        <CustomText fontSize={2} fontFamily={fonts.medium}>
          - 190
        </CustomText>
      </CustomText>

      <CustomText fontSize={2} fontFamily={fonts.bold}>
        PROCON
        <CustomText fontSize={2} fontFamily={fonts.medium}>
          - (48) 3131-5320
        </CustomText>
      </CustomText>
      <CustomText
        style={{ paddingBottom: "8px" }}
        fontSize={2}
        fontFamily={fonts.medium}
      >
        O PROCON é um órgão da Secretaria Municipal de Defesa do Cidadão, que
        foi criado com a função de defender, encaminhar e orientar o consumidor.
      </CustomText>

      <CustomText fontSize={2} fontFamily={fonts.bold}>
        SAMU
        <CustomText fontSize={2} fontFamily={fonts.medium}>
          - 192
        </CustomText>
      </CustomText>
      <CustomText
        style={{ paddingBottom: "8px" }}
        fontSize={2}
        fontFamily={fonts.medium}
      >
        Situações clínicas, como infartos e derrames.
      </CustomText>
    </div>
  );
}

export default Phones;
