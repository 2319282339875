// TODO/HINT Atualizar fontes com base no mockup

const light = '"Gilroy Light", "Helvetica", "Arial", sans-serif';
const bold = '"Gilroy Bold", "Helvetica", "Arial", sans-serif';
const medium = '"Gilroy Medium", "Helvetica", "Arial", sans-serif';
const italic = '"Gilroy Italic", "Helvetica", "Arial", sans-serif';
const regular = '"Gilroy Regular", "Helvetica", "Arial", sans-serif';
const semibold = '"Gilroy SemiBold", "Helvetica", "Arial", sans-serif';
const extrabold = '"Gilroy ExtraBold", "Helvetica", "Arial", sans-serif';
const sizes = {
  extraSmall: "0.625rem", // TODO/HINT 10px
  smaller: "0.75rem", // TODO/HINT 12px
  small: "0.875rem", // TODO/HINT 14px
  regular: "1rem", // TODO/HINT 16px
  medium: "1.125rem", // TODO/HINT 18px
  large: "1.375rem", // TODO/HINT 22px
  larger: "1.5rem", // TODO/HINT 32px
  extraLarge: "2.625rem", // TODO/HINT 42px
};

const fonts = {
  bold,
  light,
  sizes,
  medium,
  italic,
  regular,
  semibold,
  extrabold,
};

export default fonts;
