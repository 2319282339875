import { ReactComponent as Mug } from "../assets/svg/mug-hot-alt.svg";
import { ReactComponent as Bewiki } from "../assets/svg/bewiki-logo.svg";
import { ReactComponent as Behome } from "../assets/svg/Bewiki Home.svg";
import { ReactComponent as BewikiBG } from "../assets/svg/bewiki-home-bg-composition.svg";
import { ReactComponent as Brackeys } from "../assets/svg/brackeys.svg";

const SVG = {
  Mug,
  Bewiki,
  Behome,
  BewikiBG,
  Brackeys,
};

export default SVG;
