import React, { useContext } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { StorageContext } from "./contexts/StorageContext";
import pagesConfig from "./config/pagesConfig";
import pages from "./pages/index";
import { Spacing, SVG } from "./config";
import { makeStyles } from "@material-ui/core";
import { Header, Menu } from "./features";
import BewikiBG from "../src/assets/svg/bewiki-home-bg-composition.svg";
const useStyles = makeStyles((theme: any) => {
  return {
    pageContainer: {
      display: "flex",
      width: "100%",

      flexDirection: "column",

      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflowY: "auto",
      overflowX: "hidden",

      paddingInline: "clamp(24px, 5vw, 100px)",
      backgroundColor: "white",

      backgroundRepeat: "repeat",
    },
    pageContainerLanding: {
      display: "flex",
      width: "100%",

      flexDirection: "column",

      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflowY: "auto",
      overflowX: "hidden",

      backgroundColor: "#00796D",
    },
  };
});

interface IUserProps {
  Home: () => Element;
}

function App() {
  const location = useLocation();
  const classes = useStyles();
  const { isLogged, cancelToken } = useContext<any>(StorageContext);
  const [menu, setMenu] = React.useState<any>(false);

  React.useEffect(() => {
    cancelToken.cancel();
  }, [location.pathname]);

  return !isLogged ? (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <Menu openMenu={setMenu} isOpen={menu} /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            flex: 1,
            padding: 0,
            maxWidth: "100vw",
          }}
        >
          {/* <Header openMenu={() => setMenu(true)} /> */}

          <Routes>
            {pagesConfig.logged.map((item: any, index?: any) => {
              if (item.pages) {
                return item.pages.map((page: any, index?: any) => {
                  const Component = pages[page.name as keyof IUserProps];
                  return (
                    <Route
                      key={page.navigationId}
                      path={page.path}
                      element={<Component />}
                    />
                  );
                });
              }
              return null;
            })}
          </Routes>
        </div>
      </div>
    </>
  ) : (
    <div className={classes.pageContainerLanding}>
      <Routes>
        {pagesConfig.notLogged.map((item?: any, index?: any) => {
          const Component = pages[item.name as keyof IUserProps];
          return (
            <Route
              key={item.path + index}
              path={item.path}
              element={<Component />}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
