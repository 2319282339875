import { paths } from "../navigation/navigate";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import { HomeWork } from "@material-ui/icons";
const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.home,
      name: "Home",
    },
    {
      id: "1",
      name: "Landing",
      title: "",
      path: paths.landing,
      icon: HomeWork,
      working: true,
      dontShow: false,
      description: "",
    },
  ],
  logged: [
    {
      id: "0",
      groupTitle: "GERAL",
      dontShow: false,
      pages: [
        {
          id: "0",
          name: "Home",
          title: "",
          path: paths.home,
          icon: HomeWork,
          working: true,
          dontShow: false,
          description: "Formulário",
        },
        {
          id: "1",
          name: "Landing",
          title: "Painel Inicial",
          path: paths.landing,
          icon: HomeWork,
          working: true,
          dontShow: false,
          description: "Formulário",
        },
      ],
    },
  ],
};
export default pagesConfig;
