import { Grid, Switch, withStyles } from "@material-ui/core";

import {
  Input,
  InputAutocomplete,
  InputDate,
  InputEmail,
  Select,
} from "../../../components";

import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";

function GuestFields({
  formRef,
  docType,
  docId,
  setDocId,

  trevelType,
  nationList,
  setExtraLoading,

  nf,
  setNf,
  formValue,
  profissões,
  copyData,
  setFormValue,
  docIdExtra,
  setDocIdExtra,
  onBlurSubmit,
}: any) {
  const { loading, call } = hooks.useRequest();

  if (
    formRef?.formFieldsHandler?.fieldNames?.find(
      (item) => item === "documentType"
    )
  ) {
    formRef?.setFieldFocus("documentType");
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <InputAutocomplete
          disableClearable
          customOnChange={async (e, value) => {
            setDocId(value);
            const formData = await formRef?.getValues();
            if (copyData) {
              setExtraLoading(true);
              formRef?.setValue("documentTypeExtra", value);
              formRef?.setFormValue("documentTypeExtra", value);

              formRef?.clearValue("documentValueExtra", true);
              setDocIdExtra(value);
              if (value === 1) {
                formRef?.setFieldValidation(
                  "documentValueExtra",
                  validations.validateCPF
                );
              } else {
                formRef?.setFieldValidation("documentValueExtra", (value) => {
                  return null;
                });
              }

              // Simulate loading for 500ms before setting extraLoading to false
              setTimeout(() => {
                setFormValue((v) => {
                  return {
                    ...v,
                    ...formData,
                    documentValueExtra: null,
                    documentTypeExtra: value,
                  };
                });

                setExtraLoading(false);
              }, 1000);
            }

            formRef?.clearValue("documentValue", true);

            if (value === 1) {
              formRef?.setFieldValidation(
                "documentValue",
                validations.validateCPF
              );
            } else {
              formRef?.setFieldValidation("documentValue", (value) => {
                return null;
              });
            }
          }}
          customBlur={() => {
            formRef?.testFieldError("documentValue", true);
          }}
          name="documentType"
          options={docType}
          defaultValue={1}
          label="Tipo Documento*"
          required="*Campo Obrigatório"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Input
          name="documentValue"
          label={
            docId
              ? docId === 1
                ? "Nº CPF*"
                : docId === 2
                ? "Nº RG*"
                : docId === 3
                ? "Nº Certificado*"
                : "Nº Passaporte*"
              : "Nº Documento*"
          }
          required="*Campo Obrigatório"
          mask={(value) => {
            if (docId) {
              if (docId === 1) {
                return masks.CPF(value);
              } else if (docId === 2) {
                return masks.RG(value);
              } else {
                return value;
              }
            } else {
              return value;
            }
          }}
          validation={(value) => {
            if (docId === 1) {
              return validations.validateCPF(value);
            } else {
              return null;
            }
          }}
          maskToSubmit={masks.removeSpecialCharacters}
          onChange={(value, e) => {
            copyData && formRef.setValue("documentValueExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Input
          name="name"
          label="Nome Completo*"
          required="*Campo Obrigatório"
          validation={validations.isValidFullname}
          onChange={(value, e) => {
            copyData && formRef.setValue("nameExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      {/* ######################################### */}

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <InputEmail
          name="email"
          label="E-Mail*"
          required="*Campo Obrigatório"
          onChange={(value, e) => {
            copyData && formRef.setValue("emailExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2}>
        <InputDate
          name="birthDate"
          label="Nascimento*"
          required="*Campo Obrigatório"
          maxDate={new Date()}
          customOnChange={(value) => {
            copyData && formRef.setValue("birthDateExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Select
          name="gender"
          options={[
            { label: "Masculino", value: "M" },
            { label: "Feminino", value: "F" },
            { label: "Outro", value: "Outro" },
          ]}
          label="Gênero*"
          required="*Campo Obrigatório"
          onChangeCustom={(value) => {
            copyData && formRef.setValue("genderExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      {/* ######################################### */}

      <Grid item xs={12} sm={6} md={4} lg={2}>
        <InputAutocomplete
          validation={validations.validateAutocomplete}
          name="nationality"
          label="Nacionalidade*"
          required="*Campo Obrigatório"
          options={nationList}
          defaultValue={formValue?.nationality ? formValue?.nationality : -1}
          customOnChange={async (e, value) => {
            copyData && formRef.setValue("nationality", value);
            copyData && formRef.setValue("nationalityExtra", value);
            const formData = await formRef?.getValues();
            copyData &&
              setFormValue((v) => {
                return {
                  ...v,

                  ...formData,
                };
              });
            copyData && setExtraLoading(true);
          }}
          customBlur={() => {
            copyData && setExtraLoading(false);
            onBlurSubmit();
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={2}>
        <Select
          name="travelReason"
          options={trevelType}
          required="*Campo Obrigatório"
          label="Motivo da Viagem*"
          onChangeCustom={(value) => {
            copyData && formRef.setValue("travelReasonExtra", value);
          }}
        customBlur={onBlurSubmit}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={2}>
        <Input
          name="phone"
          label="Telefone Fixo"
          // mask={masks.inputMaskTELWithDDD}
          // validation={validations.checkPhone}
          maskToSubmit={masks.removeNumberMask}
          onChange={(value) => {
            copyData && formRef.setValue("phoneExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={2}>
        <Input
          name="celphone"
          label="Celular"
          // mask={masks.inputMaskTELWithDDD}
          // validation={validations.checkPhone}
          maskToSubmit={masks.removeNumberMask}
          onChange={(value) => {
            copyData && formRef.setValue("celphoneExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      {/* ######################################### */}

      <Grid item xs={12} sm={12} md={6} lg={2}>
        <Select
          name="profession"
          label="Profissão"
          placeholder="Selecionar"
          defaultValue={formValue?.profession ? formValue?.profession : -1}
          options={profissões}
          onChangeCustom={(value) => {
            copyData && formRef.setValue("professionExtra", value);
          }}
          customBlur={onBlurSubmit}
        />
      </Grid>

      {/*      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Input
          name="vehicleModel"
          label="Modelo do Veículo"
          onChange={(value, e) => {
            copyData && formRef.setValue("vehicleModelExtra", value);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Input
          name="vehiclePlate"
          label="Placa do Veículo"
          maskToSubmit={masks.removeSpecialCharacters}
          onChange={(value, e) => {
            copyData && formRef.setValue("vehiclePlateExtra", value);
          }}
        />
      </Grid> */}

      <Grid container item xs={12} sm={12} md={6} lg={3}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Styles.TitleInput>
            Precisa vincular a NF a um CNPJ?
          </Styles.TitleInput>

          <IOSSwitch
            disabled={loading}
            checked={nf}
            onChange={async (e) => {
              setNf(e.target.checked);
            }}
            name="checkedB"
          />
        </div>
      </Grid>
    </>
  );
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBlock: theme.spacing(1.5),
  },
  switchBase: {
    padding: 2.5,
    color: "#00000054",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#00796D",
      "& + $track": {
        backgroundColor: "#C4E8E4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#C4E8E4",
      border: "none",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#EBEBEB",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))((props: any) => {
  const { classes, ...switchProps } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
export default GuestFields;
