import { Grid, Switch, withStyles } from "@material-ui/core";

import {
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
  InputEmail,
  Select,
} from "../../../components";
import { fonts, SVG } from "../../../config";
import { paths } from "../../../navigation/navigate";

import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";

function LocationData({ locValue }: any) {
  return (
    <>
      <div>
        <CustomText textColor="#00796D" fontFamily={fonts.bold} fontSize={3}>
          Editar Minha Ficha de Hóspede
        </CustomText>
        <CustomText fontFamily={fonts.semibold} fontSize={2.25}>
          Para continuar, favor preencher seus dados:
        </CustomText>
      </div>
      <div
        style={{
          backgroundColor: "#E6EFEE",
          padding: "20px",
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        <CustomText
          textColor="#00796D"
          fontFamily={fonts.bold}
          fontSize={2}
          style={{ paddingBottom: "20px" }}
        >
          DETALHES DO IMÓVEL SOLICITADO PARA RESERVA
        </CustomText>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomText
              fontFamily={fonts.semibold}
              fontSize={2}
              style={{ paddingBottom: "8px" }}
            >
              Nome do Imóvel
            </CustomText>
            <div>
              <CustomText
                fontFamily={fonts.medium}
                fontSize={1.75}
                style={{
                  padding: "20px",
                  paddingBlock: "10px",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                Bewiki {locValue?.loc && "- " + locValue?.loc}
              </CustomText>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomText
              fontFamily={fonts.semibold}
              fontSize={2}
              style={{ paddingBottom: "8px" }}
            >
              Endereço
            </CustomText>
            <div>
              <CustomText
                fontFamily={fonts.medium}
                fontSize={1.75}
                style={{
                  padding: "20px",
                  paddingBlock: "10px",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                {/*  {formValue?.stayAddress
           ? formValue?.stayAddress
           : "--"} */}
                R. Hermann Blumenau, 110 - Centro, Florianópolis - SC, 88020-020
              </CustomText>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <CustomText
              fontFamily={fonts.semibold}
              fontSize={2}
              style={{ paddingBottom: "8px" }}
            >
              Período de Estadia
            </CustomText>
            <Styles.DateContainer>
              <div
                style={{
                  width: "100%",
                }}
              >
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={1.75}
                  style={{
                    padding: "20px",
                    paddingBlock: "10px",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  {locValue?.checkIn}
                </CustomText>
              </div>

              <CustomText fontFamily={fonts.medium} fontSize={1.75}>
                até
              </CustomText>

              <div
                style={{
                  width: "100%",
                }}
              >
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={1.75}
                  style={{
                    padding: "20px",
                    paddingBlock: "10px",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  {locValue?.checkOut}
                </CustomText>
              </div>
            </Styles.DateContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default LocationData;
