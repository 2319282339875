// TODO/HINT Atualizar fontes com base no mockup
const nationalityList: { value: any; label: any }[] = [
  {
    value: "AF",
    label: "Afeganistão",
  },
  {
    value: "ZA",
    label: "África do Sul",
  },
  {
    value: "AL",
    label: "Albânia",
  },
  {
    value: "DE",
    label: "Alemanha",
  },
  {
    value: "AD",
    label: "Andorra",
  },
  {
    value: "AO",
    label: "Angola",
  },
  {
    value: "AI",
    label: "Anguila",
  },
  {
    value: "AQ",
    label: "Antártida",
  },
  {
    value: "AG",
    label: "Antígua e Barbuda",
  },
  {
    value: "SA",
    label: "Arábia Saudita",
  },
  {
    value: "DZ",
    label: "Argélia",
  },
  {
    value: "AR",
    label: "Argentina",
  },
  {
    value: "AM",
    label: "Armênia",
  },
  {
    value: "AW",
    label: "Aruba",
  },
  {
    value: "AU",
    label: "Austrália",
  },
  {
    value: "AT",
    label: "Áustria",
  },
  {
    value: "AZ",
    label: "Azerbaijão",
  },
  {
    value: "BS",
    label: "Bahamas",
  },
  {
    value: "BH",
    label: "Bahrein",
  },
  {
    value: "BD",
    label: "Bangladesh",
  },
  {
    value: "BB",
    label: "Barbados",
  },
  {
    value: "BE",
    label: "Bélgica",
  },
  {
    value: "BZ",
    label: "Belize",
  },
  {
    value: "BJ",
    label: "Benim",
  },
  {
    value: "BM",
    label: "Bermudas",
  },
  {
    value: "BY",
    label: "Bielorrússia",
  },
  {
    value: "BO",
    label: "Bolívia",
  },
  {
    value: "BA",
    label: "Bósnia e Herzegovina",
  },
  {
    value: "BW",
    label: "Botswana",
  },
  {
    value: "BR",
    label: "Brasil",
  },
  {
    value: "BN",
    label: "Brunei",
  },
  {
    value: "BG",
    label: "Bulgária",
  },
  {
    value: "BF",
    label: "Burquina Fasso",
  },
  {
    value: "BI",
    label: "Burundi",
  },
  {
    value: "BT",
    label: "Butão",
  },
  {
    value: "CV",
    label: "Cabo VerdeCabo Verde",
  },
  {
    value: "CM",
    label: "Camarões",
  },
  {
    value: "KH",
    label: "CambojaCamboja",
  },
  {
    value: "CA",
    label: "Canadá",
  },
  {
    value: "QA",
    label: "Catar",
  },
  {
    value: "KZ",
    label: "Cazaquistão",
  },
  {
    value: "TD",
    label: "Chade",
  },
  {
    value: "CZ",
    label: "Chéquia",
  },
  {
    value: "CL",
    label: "Chile",
  },
  {
    value: "CN",
    label: "China",
  },
  {
    value: "CY",
    label: "Chipre",
  },
  {
    value: "CO",
    label: "Colômbia",
  },
  {
    value: "KM",
    label: "Comores",
  },
  {
    value: "KP",
    label: "Coreia do Norte",
  },
  {
    value: "KR",
    label: "Coreia do SulCoreia do Sul",
  },
  {
    value: "CI",
    label: "Costa do Marfim",
  },
  {
    value: "CR",
    label: "Costa Rica",
  },
  {
    value: "HR",
    label: "Croácia",
  },
  {
    value: "CU",
    label: "Cuba",
  },
  {
    value: "CW",
    label: "Curaçau",
  },
  {
    value: "DK",
    label: "Dinamarca",
  },
  {
    value: "DJ",
    label: "Djibouti",
  },
  {
    value: "DM",
    label: "Dominica",
  },
  {
    value: "EG",
    label: "Egito",
  },
  {
    value: "SV",
    label: "El Salvador",
  },
  {
    value: "AE",
    label: "Emirados Árabes Unidos",
  },
  {
    value: "EC",
    label: "Equador",
  },
  {
    value: "ER",
    label: "Eritreia",
  },
  {
    value: "SK",
    label: "Eslováquia",
  },
  {
    value: "SI",
    label: "Eslovênia",
  },
  {
    value: "ES",
    label: "Espanha",
  },
  {
    value: "SZ",
    label: "Essuatíni",
  },
  {
    value: "FM",
    label: "Estados Federados da Micronésia",
  },
  {
    value: "US",
    label: "Estados Unidos",
  },
  {
    value: "EE",
    label: "Estónia",
  },
  {
    value: "ET",
    label: "Etiópia",
  },
  {
    value: "FJ",
    label: "Fiji",
  },
  {
    value: "PH",
    label: "Filipinas",
  },
  {
    value: "FI",
    label: "Finlândia",
  },
  {
    value: "FR",
    label: "França",
  },
  {
    value: "GA",
    label: "Gabão",
  },
  {
    value: "GM",
    label: "Gâmbia",
  },
  {
    value: "GH",
    label: "Gana",
  },
  {
    value: "GE",
    label: "Geórgia",
  },
  {
    value: "GI",
    label: "Gibraltar",
  },
  {
    value: "GD",
    label: "Granada",
  },
  {
    value: "GR",
    label: "Grécia",
  },
  {
    value: "GL",
    label: "Gronelândia",
  },
  {
    value: "GP",
    label: "Guadalupe",
  },
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "GT",
    label: "Guatemala",
  },
  {
    value: "GG",
    label: "Guernsey",
  },
  {
    value: "GY",
    label: "Guiana",
  },
  {
    value: "GF",
    label: "Guiana Francesa",
  },
  {
    value: "GN",
    label: "Guiné",
  },
  {
    value: "GQ",
    label: "Guiné Equatorial",
  },
  {
    value: "GW",
    label: "Guiné-Bissau",
  },
  {
    value: "HT",
    label: "Haiti",
  },
  {
    value: "HN",
    label: "Honduras",
  },
  {
    value: "HK",
    label: "Hong Kong",
  },
  {
    value: "HU",
    label: "Hungria",
  },
  {
    value: "YE",
    label: "Iêmen",
  },
  {
    value: "BV",
    label: "Ilha Bouvet",
  },
  {
    value: "IM",
    label: "Ilha de Man",
  },
  {
    value: "CX",
    label: "Ilha do Natal",
  },
  {
    value: "HM",
    label: "Ilha Heard e Ilhas McDonald",
  },
  {
    value: "NF",
    label: "Ilha Norfolk",
  },
  {
    value: "AX",
    label: "Ilhas Åland",
  },
  {
    value: "KY",
    label: "Ilhas Caimã",
  },
  {
    value: "CC",
    label: "Ilhas Cocos (Keeling)",
  },
  {
    value: "CK",
    label: "Ilhas Cook",
  },
  {
    value: "FO",
    label: "Ilhas Feroé",
  },
  {
    value: "GS",
    label: "Ilhas Geórgia do Sul e Sandwich do Sul",
  },
  {
    value: "FK",
    label: "Ilhas Malvinas",
  },
  {
    value: "MH",
    label: "Ilhas Marshall",
  },
  {
    value: "MU",
    label: "Ilhas Maurícias",
  },
  {
    value: "UM",
    label: "Ilhas Menores Distantes dos Estados Unidos",
  },
  {
    value: "SB",
    label: "Ilhas Salomão",
  },
  {
    value: "VI",
    label: "Ilhas Virgens Americanas",
  },
  {
    value: "VG",
    label: "Ilhas Virgens Britânicas",
  },
  {
    value: "IN",
    label: "Índia",
  },
  {
    value: "ID",
    label: "Indonésia",
  },
  {
    value: "IR",
    label: "IrãIrão",
  },
  {
    value: "IQ",
    label: "Iraque",
  },
  {
    value: "IE",
    label: "Irlanda",
  },
  {
    value: "IS",
    label: "Islândia",
  },
  {
    value: "IL",
    label: "Israel",
  },
  {
    value: "IT",
    label: "Itália",
  },
  {
    value: "JM",
    label: "Jamaica",
  },
  {
    value: "JP",
    label: "Japão",
  },
  {
    value: "JE",
    label: "Jersey",
  },
  {
    value: "JO",
    label: "Jordânia",
  },
  {
    value: "KI",
    label: "Kiribati",
  },
  {
    value: "KW",
    label: "Kuwait",
  },
  {
    value: "LA",
    label: "LaosLaos",
  },
  {
    value: "LS",
    label: "Lesoto",
  },
  {
    value: "LV",
    label: "Letônia",
  },
  {
    value: "LB",
    label: "Líbano",
  },
  {
    value: "LR",
    label: "Libéria",
  },
  {
    value: "LY",
    label: "Líbia",
  },
  {
    value: "LI",
    label: "Liechtenstein",
  },
  {
    value: "LT",
    label: "Lituânia",
  },
  {
    value: "LU",
    label: "Luxemburgo",
  },
  {
    value: "MO",
    label: "Macau",
  },
  {
    value: "MK",
    label: "Macedónia do NorteMacedônia do Norte",
  },
  {
    value: "MG",
    label: "Madagáscar",
  },
  {
    value: "MY",
    label: "Malásia",
  },
  {
    value: "MW",
    label: "Malawi",
  },
  {
    value: "MV",
    label: "Maldivas",
  },
  {
    value: "ML",
    label: "Mali",
  },
  {
    value: "MT",
    label: "Malta",
  },
  {
    value: "MP",
    label: "Marianas Setentrionais",
  },
  {
    value: "MA",
    label: "Marrocos",
  },
  {
    value: "MQ",
    label: "Martinica",
  },
  {
    value: "MR",
    label: "Mauritânia",
  },
  {
    value: "YT",
    label: "Mayotte",
  },
  {
    value: "MX",
    label: "México",
  },
  {
    value: "MZ",
    label: "Moçambique",
  },
  {
    value: "MD",
    label: "Moldávia",
  },
  {
    value: "MC",
    label: "Mónaco",
  },
  {
    value: "MN",
    label: "MongóliaMongólia",
  },
  {
    value: "MS",
    label: "Monserrate",
  },
  {
    value: "ME",
    label: "Montenegro",
  },
  {
    value: "MM",
    label: "MyanmarMyanmar",
  },
  {
    value: "NA",
    label: "Namíbia",
  },
  {
    value: "NR",
    label: "Nauru",
  },
  {
    value: "NP",
    label: "Nepal",
  },
  {
    value: "NI",
    label: "Nicarágua",
  },
  {
    value: "NE",
    label: "Níger",
  },
  {
    value: "NG",
    label: "Nigéria",
  },
  {
    value: "NU",
    label: "Niue",
  },
  {
    value: "NO",
    label: "Noruega",
  },
  {
    value: "NC",
    label: "Nova Caledônia",
  },
  {
    value: "NZ",
    label: "Nova Zelândia",
  },
  {
    value: "OM",
    label: "OmãOmã",
  },
  {
    value: "NL",
    label: "Países Baixos",
  },
  {
    value: "BQ",
    label: "Países Baixos Caribenhos",
  },
  {
    value: "PW",
    label: "Palau",
  },
  {
    value: "PS",
    label: "Palestina",
  },
  {
    value: "PA",
    label: "Panamá",
  },
  {
    value: "PG",
    label: "Papua-Nova Guiné",
  },
  {
    value: "PK",
    label: "Paquistão",
  },
  {
    value: "PY",
    label: "Paraguai",
  },
  {
    value: "PE",
    label: "Peru",
  },
  {
    value: "PN",
    label: "Pitcairn",
  },
  {
    value: "PF",
    label: "Polinésia Francesa",
  },
  {
    value: "PL",
    label: "Polónia",
  },
  {
    value: "PR",
    label: "Porto Rico",
  },
  {
    value: "PT",
    label: "PortugalPortugal",
  },
  {
    value: "KE",
    label: "Quênia",
  },
  {
    value: "KG",
    label: "Quirguistão",
  },
  {
    value: "GB",
    label: "Reino Unido",
  },
  {
    value: "CF",
    label: "República Centro-Africana",
  },
  {
    value: "CD",
    label: "República Democrática do Congo",
  },
  {
    value: "CG",
    label: "República do Congo",
  },
  {
    value: "DO",
    label: "República Dominicana",
  },
  {
    value: "RE",
    label: "Reunião",
  },
  {
    value: "RO",
    label: "Roménia",
  },
  {
    value: "RW",
    label: "Ruanda",
  },
  {
    value: "RU",
    label: "Rússia",
  },
  {
    value: "EH",
    label: "Saara Ocidental",
  },
  {
    value: "WS",
    label: "Samoa",
  },
  {
    value: "AS",
    label: "Samoa Americana",
  },
  {
    value: "SM",
    label: "San Marino",
  },
  {
    value: "SH",
    label: "Santa Helena, Ascensão e Tristão da Cunha",
  },
  {
    value: "LC",
    label: "Santa Lúcia",
  },
  {
    value: "BL",
    label: "São Bartolomeu",
  },
  {
    value: "KN",
    label: "São Cristóvão e Neves",
  },
  {
    value: "MF",
    label: "São Martinho",
  },
  {
    value: "SX",
    label: "São Martinho",
  },
  {
    value: "PM",
    label: "São Pedro e Miquelão",
  },
  {
    value: "ST",
    label: "São Tomé e Príncipe",
  },
  {
    value: "VC",
    label: "São Vicente e Granadinas",
  },
  {
    value: "SC",
    label: "Seicheles",
  },
  {
    value: "SN",
    label: "Senegal",
  },
  {
    value: "SL",
    label: "Serra Leoa",
  },
  {
    value: "RS",
    label: "Sérvia",
  },
  {
    value: "SG",
    label: "SingapuraSingapura",
  },
  {
    value: "SY",
    label: "SíriaSíria",
  },
  {
    value: "SO",
    label: "Somália",
  },
  {
    value: "LK",
    label: "Sri Lanka",
  },
  {
    value: "SD",
    label: "Sudão",
  },
  {
    value: "SS",
    label: "Sudão do Sul",
  },
  {
    value: "SE",
    label: "Suécia",
  },
  {
    value: "CH",
    label: "Suíça",
  },
  {
    value: "SR",
    label: "Suriname",
  },
  {
    value: "SJ",
    label: "SvalbardeJan Mayen",
  },
  {
    value: "TH",
    label: "Tailândia",
  },
  {
    value: "TW",
    label: "Taiwan",
  },
  {
    value: "TJ",
    label: "Tajiquistão",
  },
  {
    value: "TZ",
    label: "Tanzânia",
  },
  {
    value: "TF",
    label: "Terras Austrais e Antárticas Francesas",
  },
  {
    value: "IO",
    label: "Território Britânico do Oceano Índico",
  },
  {
    value: "TL",
    label: "Timor-Leste",
  },
  {
    value: "TG",
    label: "Togo",
  },
  {
    value: "TO",
    label: "Tonga",
  },
  {
    value: "TK",
    label: "Toquelau",
  },
  {
    value: "TT",
    label: "Trinidad e Tobago",
  },
  {
    value: "TN",
    label: "Tunísia",
  },
  {
    value: "TC",
    label: "Turcas e Caicos",
  },
  {
    value: "TM",
    label: "Turquemenistão",
  },
  {
    value: "TR",
    label: "Turquia",
  },
  {
    value: "TV",
    label: "Tuvalu",
  },
  {
    value: "UA",
    label: "Ucrânia",
  },
  {
    value: "UG",
    label: "Uganda",
  },
  {
    value: "UY",
    label: "Uruguai",
  },
  {
    value: "UZ",
    label: "Uzbequistão",
  },
  {
    value: "VU",
    label: "Vanuatu",
  },
  {
    value: "VA",
    label: "Vaticano",
  },
  {
    value: "VE",
    label: "Venezuela",
  },
  {
    value: "VN",
    label: "Vietname",
  },
  {
    value: "WF",
    label: "Wallis e Futuna",
  },
  {
    value: "ZM",
    label: "Zâmbia",
  },
  {
    value: "ZW",
    label: "Zimbabwe",
  },
];

export default nationalityList;
