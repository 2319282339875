import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dateFns from "@date-io/date-fns";
import styled from "styled-components";
import FontStyles from "../styles/fontStyles";
import {
  FormControl,
  TextFieldProps,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import brLocate from "date-fns/locale/pt-BR";
import { Colors, fonts } from "../../config";
import { useFormFull } from "form-full";
import { validations } from "../../utils";
import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper";

export type TextInputDateBaseProps = {
  label?: string;
  alternativeColors?: boolean;
  inputStyle?: styled;
  required?: string;
  readOnly?: boolean;
  icon?: any;
  theme?: any;
  disableError?: boolean;
  borderRadius?: any;
  variant?: WrapperVariant;
  views?: DatePickerView[];
  autoOk?: boolean;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  autoComplete?: string;
  end?: string;
  restProps?: any;
  name: string;
  customOnChange?: any;
  customBlur?: any;
};

const customTheme: Theme = createTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.input,
        borderRadius: 5,
        height: 50,
        fontFamily: fonts.medium,
        fontSize: fonts.sizes.small,
        "&:hover": {
          backgroundColor: Colors.input,
        },
      },
      input: {
        padding: "6px 8px 7px 8px",
      },
      adornedStart: {
        padding: "0px !important",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        margin: "0px !important",
      },
      root: {
        margin: "0px !important",
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.green,
        margin: "0px !important",
      },
    },
    MuiTypography: {
      alignCenter: {
        color: Colors.grayText,
      },
    },
    MuiButton: {
      label: {
        color: Colors.green,
      },
    },
  },
});

const Label = styled.p(({ theme, $witherror }) => {
  const { palette: colors, spacing } = theme;
  return {
    margin: 0,
    marginBottom: spacing(0.5),
    ...FontStyles.semibold16,

    color: $witherror ? colors.error.main : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

function InputDateComponent(props: TextInputDateBaseProps) {
  const { value, error, onBlur, onChange, ref, ffHandler } = useFormFull.field({
    ...props,
    validation: (data) =>
      validations.inputDate(data, props.maxDate, props.minDate),
  });

  const {
    label,
    icon,
    theme,
    required,
    disableError,
    borderRadius,
    variant,
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    autoComplete,
    end,
    customOnChange,
    customBlur,
    ...restProps
  } = props;
  return (
    <FormControl fullWidth error={Boolean(error)}>
      <MuiPickersUtilsProvider locale={brLocate} utils={dateFns}>
        <Label $witherror={Boolean(error) ? true : undefined}>
          {label}
          {Boolean(required)}
        </Label>
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            InputAdornmentProps={{ position: "start" }}
            {...restProps}
            {...(readOnly ? { open: !readOnly } : {})}
            inputVariant="filled"
            format={format ? format : "dd/MM/yyyy"}
            minDate={minDate !== null ? minDate : undefined}
            maxDate={maxDate !== null ? maxDate : undefined}
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly,
            }}
            value={value ? value : null}
            required={Boolean(required)}
            variant={variant}
            fullWidth
            views={views}
            inputRef={ref}
            autoOk={autoOk}
            onChange={(date) => {
              onChange(null, date);
              customOnChange && customOnChange(date);
              setTimeout(() => {
                onBlur(() => {
                  ffHandler?.testFieldError(props.name);
                  customBlur();
                });
              }, 10);
            }}
            error={Boolean(error)}
            helperText={disableError ? null : error ? error : " "}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export default InputDateComponent;
