import { Grid, Switch, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import React, { useEffect } from "react";
import {
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
  InputEmail,
  Select,
} from "../../../components";
import { fonts } from "../../../config";
import { api } from "../../../services";

import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";

const docType = [
  { label: "CPF", value: 1 },
  { label: "Passaporte", value: 4 },
];

const trevelType = [
  { label: "Negócios ", value: 2 },
  { label: "Lazer ", value: 3 },
];

function ExtraGuest({
  formRef,

  setStateSelected,
  setCitiesExtra,

  formValue,

  setFormValue,

  setCopy,
  extraLoading,
  nationalityList,
  profissões,
  setExtraLoading,
  copyData,
  setCopyData,
  docId,
  setDocId,

  setInternationalAddress,
  internationalAddress,
  onBlurSubmit,
}: any) {
  const { loading, call, falseLoading } = hooks.useRequest();

  const loadCities = async (stateId: string | null): Promise<any[]> => {
    if (stateId === null) {
      setStateSelected(false);
      setCitiesExtra([]);
      return [];
    }

    return new Promise<any[]>(async (resolve, reject) => {
      await call(null, api.getCities(stateId), async (response) => {
        if (response.ok) {
          setStateSelected(true);
          const formData = await formRef?.getValues();

          const citiesList = response?.data?.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          setCitiesExtra(citiesList);
          setFormValue((v) => {
            return { ...v, ...formData, stateExtra: stateId };
          });
          resolve(citiesList);
        } else {
          reject([]);
        }
      });
    });
  };

  const getSameData = async (same) => {
    const formData = await formRef?.getValues();

    if (same) {
      setInternationalAddress((old) => {
        return { ...old, extraAddress: old?.address };
      });
      let holdCitiesList;

      if (formData?.state && !internationalAddress?.address) {
        holdCitiesList = await loadCities(formData?.state);
      } else {
        holdCitiesList = await loadCities(null);
        falseLoading();
      }
      setDocId(formData.documentValueExtra);

      await setExtraLoading(true);
      await setFormValue((old) => {
        const cityExtra = !internationalAddress?.address
          ? holdCitiesList?.find((item) => {
              if (
                item?.label === formData?.city ||
                item?.value === formData?.city
              ) {
                return item;
              }
            })?.value
          : formData?.city;

        let newData = {
          ...old,
          ...formData,
          stayType: formValue?.stayType,
          numberExtra: formData?.number,
          streetExtra: formData?.street,
          complementExtra: formData?.complement,
          districtExtra: formData?.district,
          cityExtra: cityExtra,
          stateExtra: formData?.state,
          countryExtra: formData?.country,
          postal_codeExtra: formData?.postal_code,

          documentTypeExtra: formData?.documentType,
          documentValueExtra: formData?.documentValue,
          nameExtra: formData?.name,
          emailExtra: formData?.email,
          birthDateExtra: masks.formatDateForm(formData?.birthDate),
          genderExtra: formData?.gender,
          nationalityExtra: formData?.nationality,

          phoneExtra: formData?.phone,
          /*             formData?.phone?.length === 11 || formData?.phone?.length === 12
              ? "55" + formData?.phone
              : formData?.phone, */

          celphoneExtra: formData?.celphone,

          travelReasonExtra: formData?.travelReason,
          professionExtra: formData?.profession,
        };

        return newData;
      });
    } else {
      await setExtraLoading(true);
      setFormValue((old) => {
        let newDataClear = {
          ...old,
          ...formData,
          stayType: old?.stayType,
          countryExtra: old?.countryExtra,

          numberExtra: null,
          streetExtra: null,
          complementExtra: null,
          districtExtra: null,
          cityExtra: null,
          stateExtra: null,

          postal_codeExtra: null,

          documentTypeExtra: null,
          documentValueExtra: null,
          nameExtra: null,
          emailExtra: null,
          birthDateExtra: null,
          genderExtra: null,
          nationalityExtra: null,

          phoneExtra: null,
          celphoneExtra: null,

          travelReasonExtra: null,
          professionExtra: null,
        };
        return newDataClear;
      });
    }
    setCopy(same);
    setTimeout(() => {
      setExtraLoading(false);
    }, 500);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div
          style={{
            width: "100%",
            height: "1px",
            marginBlock: "20px",
            backgroundColor: "#303036",
          }}
        ></div>
        <CustomText
          fontFamily={fonts.bold}
          fontSize={2}
          style={{ paddingBottom: "14px", paddingTop: "8px" }}
        >
          DADOS ACOMPANHANTE
        </CustomText>
      </Grid>
      {extraLoading ? (
        <Grid
          style={{ display: "flex", alignItems: "center" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <div style={{ width: "100%" }}>
            <Skeleton variant="rect" width="100%" height={200} />
          </div>
        </Grid>
      ) : (
        <>
          {(formValue?.stayType === "SC" || formValue?.stayType === "SH") && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Select
                name="beds"
                required="*Campo Obrigatório"
                placeholder="Selecionar"
                options={[
                  {
                    label: "02 camas de solteiro",
                    value: "single",
                  },
                  {
                    label: "01 cama de casal",
                    value: "couple",
                  },
                ]}
                label="Disposição das Camas*"
                customBlur={onBlurSubmit}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <InputAutocomplete
              id={"teste" + docId}
              disableClearable
              readOnly={copyData}
              customOnChange={(e, value) => {
                setDocId(value);

                formRef?.clearValue("documentValueExtra", true);

                if (value === 1) {
                  formRef?.setFieldValidation(
                    "documentValueExtra",
                    validations.validateCPF
                  );
                } else {
                  formRef?.setFieldValidation("documentValueExtra", (value) => {
                    return null;
                  });
                }
              }}
              customBlur={() => {
                formRef?.testFieldError("documentValueExtra", true);
                onBlurSubmit();
              }}
              name="documentTypeExtra"
              options={docType}
              label="Tipo Documento*"
              required="*Campo Obrigatório"
              defaultValue={formValue ? formValue?.documentTypeExtra : 1}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Input
              readOnly={copyData}
              name="documentValueExtra"
              label={
                docId
                  ? docId === 1
                    ? "Nº CPF*"
                    : docId === 2
                    ? "Nº RG*"
                    : docId === 3
                    ? "Nº Certificado*"
                    : "Nº Passaporte*"
                  : "Nº Documento*"
              }
              required="*Campo Obrigatório"
              mask={(value) => {
                if (docId) {
                  if (docId === 1) {
                    return masks.CPF(value);
                  } else if (docId === 2) {
                    return masks.RG(value);
                  } else {
                    return value;
                  }
                } else {
                  return value;
                }
              }}
              validation={(value) => {
                if (docId === 1) {
                  return validations.validateCPF(value);
                } else {
                  return null;
                }
              }}
              maskToSubmit={masks.removeSpecialCharacters}
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Input
              readOnly={copyData}
              name="nameExtra"
              label="Nome Completo*"
              required="*Campo Obrigatório"
              customBlur={onBlurSubmit}
            />
          </Grid>
          {/* ######################################### */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <InputEmail
              readOnly={copyData}
              name="emailExtra"
              label="E-Mail*"
              required="*Campo Obrigatório"
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <InputDate
              readOnly={copyData}
              name="birthDateExtra"
              label="Nascimento*"
              required="*Campo Obrigatório"
              maxDate={new Date()}
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              readOnly={copyData}
              name="genderExtra"
              options={[
                { label: "Masculino", value: "M" },
                { label: "Feminino", value: "F" },
                { label: "Outro", value: "Outro" },
              ]}
              label="Gênero*"
              required="*Campo Obrigatório"
              customBlur={onBlurSubmit}
            />
          </Grid>
          {/* ######################################### */}
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputAutocomplete
              validation={validations.validateAutocomplete}
              readOnly={copyData}
              name="nationalityExtra"
              label="Nacionalidade*"
              required="*Campo Obrigatório"
              options={nationalityList}
              defaultValue={formValue ? formValue?.nationalityExtra : null}
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <Select
              readOnly={copyData}
              name="travelReasonExtra"
              options={trevelType}
              required="*Campo Obrigatório"
              label="Motivo da Viagem*"
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Input
              readOnly={copyData}
              name="phoneExtra"
              label="Telefone Fixo"
              // mask={masks.inputMaskTELWithDDD}
              // validation={validations.checkPhone}
              maskToSubmit={masks.removeNumberMask}
              customBlur={onBlurSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <Input
              readOnly={copyData}
              name="celphoneExtra"
              label="Celular"
              // mask={masks.inputMaskTELWithDDD}
              // validation={validations.checkPhone}
              maskToSubmit={masks.removeNumberMask}
              customBlur={onBlurSubmit}
            />
          </Grid>
          {/* ######################################### */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              readOnly={copyData}
              options={profissões}
              name="professionExtra"
              label="Profissão"
              placeholder="Selecionar"
              defaultValue={formValue ? formValue?.professionExtra : null}
              customBlur={onBlurSubmit}
            />
          </Grid>
        </>
      )}
    </>
  );
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBlock: theme.spacing(1.5),
  },
  switchBase: {
    padding: 2.5,
    color: "#00000054",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#00796D",
      "& + $track": {
        backgroundColor: "#C4E8E4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#C4E8E4",
      border: "none",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#EBEBEB",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))((props: any) => {
  const { classes, ...switchProps } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ExtraGuest;
