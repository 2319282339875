import { CustomText } from "../../../components";
import CustomTable from "../../../components/others/Table";
import { fonts, Spacing } from "../../../config";

function Address() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingInline: Spacing(3.12),
        maxWidth: "820px",
        gap: "8px",
      }}
    >
      <CustomText fontSize={2} fontFamily={fonts.bold}>
        Endereço
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        Avenida Paulo Fontes, 1101 <br></br>
        Centro Florianópolis <br></br>
        SC CEP: 88010-230
      </CustomText>
    </div>
  );
}

export default Address;
