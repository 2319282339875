import { CustomText } from "../../../components";
import CustomTable from "../../../components/others/Table";
import { fonts, Spacing } from "../../../config";

function Mob() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingInline: Spacing(3.12),
        maxWidth: "820px",
        gap: "8px",
      }}
    >
      <CustomText fontSize={2} fontFamily={fonts.bold}>
        Transporte Público
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        O transporte público é composto por uma rede de ônibus que atende grande
        parte da cidade, com linhas regulares e tarifas acessíveis.
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        Para facilitar a mobilidade dos visitantes, o Passe Rápido oferece o
        cartão Turista, que possibilita ao portador usufruir dos benefícios do
        pagamento eletrônico (economia e segurança) para aproveitar ainda mais
        os pontos turísticos da cidade!
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        O cartão pode ser adquirido no Passe Rápido no TICEN ou em qualquer
        bilheteria dos terminais de bairro, ao custo de R$ 3, e pode ser
        recarregado na bilheteria dos terminais de integração de Florianópolis.
      </CustomText>
      <CustomText fontSize={1.5} fontFamily={fonts.italic} textColor="#00796D">
        Dica: Baixe o app “Floripa no Ponto 2.0” na loja Google Play e Apple
        Store para monitorar os horários dos ônibus em tempo real.
      </CustomText>

      <CustomText fontSize={2} fontFamily={fonts.bold}>
        Transporte por Aplicativo
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        Além disso, é possível utilizar carros de aluguel e serviços de carros
        por aplicativo, como Uber e 99, para se deslocar pela cidade de forma
        mais conveniente.
      </CustomText>

      <CustomText fontSize={2} fontFamily={fonts.bold}>
        Transporte Alternativo
      </CustomText>
      <CustomText fontSize={2} fontFamily={fonts.medium}>
        Outra opção é a bicicleta, que é uma forma saudável e sustentável de
        explorar a cidade, com diversas ciclovias e rotas ciclísticas.
      </CustomText>
    </div>
  );
}

export default Mob;
