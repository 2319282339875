import { create } from "apisauce";
import buildQueryParamsUrl from "./helpers/buildQueryParamsUrl";
import caller from "./helpers/caller";

const apiUrl = process.env.REACT_APP_API;

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

async function modelRequest(data) {
  return caller(api.post, "/", null, data);
}

async function getStates() {
  return caller(api.get, "/state", null);
}

async function getCountry() {
  return caller(api.get, "/country", null);
}

async function getCities(data) {
  return caller(api.get, buildQueryParamsUrl("/city", { stateId: data }), null);
}

async function getTravelReason() {
  return caller(api.get, "/behome/stay/travelreason", null);
}

async function sendGuest(guestCode, data) {
  return caller(api.put, `/behome/external/guest/${guestCode}`, null, data);
}

async function saveGuest(guestCode, data) {
  return caller(api.patch, `/behome/external/guest/${guestCode}`, null, data);
}

async function getGuest(guestCode) {
  return caller(api.get, `/behome/external/guest/${guestCode}`, null);
}

async function getAddressCep(cep) {
  return caller(api.get, `/address/${cep}`, null);
}

const apiServices = {
  getAddressCep,
  modelRequest,
  getStates,

  getTravelReason,
  getCities,
  getCountry,
  sendGuest,
  getGuest,
  saveGuest,
};

export default apiServices;
