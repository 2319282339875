import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../../components";

const Container = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    width: "100%",
  };
});

const ContainerHead = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "white",
    padding: "clamp(12px, 2vw, 30px)",
    paddingInline: "clamp(12px, 4vw, 80px)",

    width: "100%",
    flexWrap: "wrap",
    [`@media (max-width:${386 - 1}px)`]: {
      justifyContent: "flex-start",
    },
  };
});

const ContainerForm = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "clamp(12px, 2vw, 30px)",
    paddingInline: "clamp(12px, 4vw, 80px)",

    width: "100%",
    gap: "10px",
  };
});

const ContainerVersion = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "clamp(12px, 2vw, 30px)",
    paddingInline: "clamp(12px, 4vw, 80px)",

    width: "100%",
    gap: "10px",
  };
});

const DateContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "12px",
    [`@media (max-width:${386 - 1}px)`]: {
      flexDirection: "column",
    },
  };
});

const HeaderContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    gap: "14px",
    alignItems: "center",

    width: "fit-content",

    [`@media (max-width:${386 - 1}px)`]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
  };
});
const LocationContainer = styled.div(({ theme }) => {
  return {
    alignItems: "center",
    display: "flex",
    gap: "14px",

    width: "100%",

    [`@media (max-width:${386 - 1}px)`]: {
      flexDirection: "column",
      gap: "0px",
      marginBottom: "12px",
    },
  };
});

const ButtonSite = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      width: "fit-content",
      [`@media (max-width:${386 - 1}px)`]: {
        width: "100%",
      },
    },
  };
});

const TitleInput = styled.p(({ theme, $withError, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold16,

    padding: 0,
    margin: 0,
    color: $withError ? colors.error.main : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

const Styles = {
  Container,
  ContainerHead,
  ContainerForm,
  DateContainer,
  HeaderContent,
  LocationContainer,
  ButtonSite,
  TitleInput,
  ContainerVersion,
};

export default Styles;
