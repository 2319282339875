import { CustomText } from "../../components";

import { fonts, Spacing, SVG } from "../../config";

import Styles from "./styles";

import { useLocation, useNavigate } from "react-router-dom";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Button, Grid, IconButton } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import { ChevronRight, Facebook, LinkedIn } from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiBusStop, mdiMapSearch, mdiPhoneClassic } from "@mdi/js";
import BeStore from "../../assets/images/bewiki-store.png";
import CheckIn from "../../assets/images/undraw-check-in.png";
import { hooks } from "../../utils";
import { api } from "../../services";
import React from "react";
import { customModal } from "../../components/modals/utils";
import Mob from "./components/mob";
import Address from "./components/address";
import Phones from "./components/phones";

function Landing() {
  const location = useLocation();
  const navigation = useNavigate();
  const [isMounted, setMount] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const { loading, call } = hooks.useRequest();

  const Mount = React.useCallback(async () => {
    if (location?.search) {
      call(
        null,
        api.getGuest(location?.search?.replace("?guestCode=", "")),
        async (response) => {
          if (response.ok) {
            setData(response?.data);
          }
        }
      );
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const whats = [
    {
      img: require("../../assets/images/index-01@2x.png"),
      label: "Alterar Reserva",
      onClick: () => {
        window.open(
          `https://wa.me/554896793652?text=${`Ola sou o ${
            data?.name ? data?.name : "..."
          } (Reserva ${
            data.stayNumber
          }), e gostaria de alterar os dados da reserva`}`,
          "_blank"
        );
      },
      subText:
        "Altere os dados de sua reserva como data, amenidades inclusas, ou até o tipo de studio que deseja ficar!",
    },
  ];

  const infos = [
    {
      icon: mdiMapSearch,
      label: "Mobilidade",
      onClick: () => {
        customModal.setInfos(
          "Mobilidade",
          [
            "Florianópolis oferece diversas opções de mobilidade para seus moradores e visitantes.",
          ],
          { label: "Ok, entendi", onClick: () => customModal.close() },
          null,
          <Mob />,
          false,
          true
        );
      },
    },
    {
      icon: mdiBusStop,
      label: "Rodoviária",
      onClick: () => {
        customModal.setInfos(
          "Rodoviária",
          [
            "A Rodoviária de Florianópolis, também conhecida como Terminal Rodoviário Rita Maria, é um ponto crucial para quem deseja viajar para diferentes destinos de Santa Catarina, principais cidades do Brasil e países vizinhos.",
            "Localizada em uma posição estratégica no centro da cidade, próxima às pontes Governador Pedro Ivo Campos e Colombo Salles, que conectam a ilha ao continente, a Rodoviária de Florianópolis é um local movimentado que opera diariamente centenas de chegadas e partidas de ônibus.",
            "Com cerca de 20 companhias de ônibus que atuam no Terminal Rita Maria, é possível encontrar mais de 500 rotas para diferentes destinos.",
            "Se você está se perguntando como chegar na Rodoviária de Florianópolis, saiba que ela está localizada na Avenida Paulo Fontes, número 1101, no centro da cidade.",
            "O local fica a poucos minutos a pé do TICEN, o terminal de integração do transporte urbano, e ao lado da cabeceira das pontes Pedro Ivo e Colombo Salles. Isso torna a rodoviária de fácil acesso para aqueles que partem de quase todos os pontos da cidade.",
            "Além disso, se você preferir ir de carro, a rodoviária oferece estacionamento 24 horas para sua comodidade.",
          ],
          { label: "Ok, entendi", onClick: () => customModal.close() },
          null,
          <Address />,
          false,
          true
        );
      },
    },
    {
      icon: mdiPhoneClassic,
      label: "Telefones Úteis",
      onClick: () => {
        customModal.setInfos(
          "Telefones Úteis",
          [],
          { label: "Ok, entendi", onClick: () => customModal.close() },
          null,
          <Phones />,
          false,
          true
        );
      },
    },
  ];

  const pics = [
    require("../../assets/images/toDoFloripa (1).png"),
    require("../../assets/images/toDoFloripa (8).png"),
    require("../../assets/images/toDoFloripa (7).png"),
    require("../../assets/images/toDoFloripa (6).png"),
    require("../../assets/images/toDoFloripa (5).png"),
    require("../../assets/images/toDoFloripa (4).png"),
    require("../../assets/images/toDoFloripa (3).png"),
    require("../../assets/images/toDoFloripa (2).png"),
  ];

  const picsInsta = [
    require("../../assets/images/instaImg (3).png"),
    require("../../assets/images/instaImg (2).png"),
    require("../../assets/images/instaImg (1).png"),
  ];

  const picsMarket = [
    {
      img: require("../../assets/images/Market/logo-inpot.png"),
      color: "#F1F1F1",
      textColor: "#000000",
      label: "INPOT",
      subText: "Alimentação saudável.",
    },
    {
      img: require("../../assets/images/Market/logo-noir-sushi.png"),
      color: "#000000",
      textColor: "#FFFFFF",
      label: "NOIR SUSHI",
      subText: "Gastronomia japonesa.",
    },
    {
      img: require("../../assets/images/Market/logo-iam-burger.png"),
      color: "#FFFFFF",
      textColor: "#000000",
      label: "I AM BURGER",
      subText: "Hambúrgueres e grelhados.",
    },
  ];

  const openPics = () => {
    window.open("https://visitefloripa.com.br/o-que-ver/", "_blank");
  };

  const openInsta = () => {
    window.open("https://www.instagram.com/bewikioficial/", "_blank");
  };

  const openMarket = () => {
    window.open("https://blimboo.space/bewikistore/aK77a", "_blank");
  };

  const openMarketFood = (index: number) => {
    if (index === 0) {
      window.open("https://bewiki.notato.menu/inpot", "_blank");
    } else if (index === 1) {
      window.open("https://bewiki.notato.menu/noir", "_blank");
    } else if (index === 2) {
      window.open("https://bewiki.notato.menu/iamburger", "_blank");
    } else {
      window.open("https://bewiki.notato.menu/tomato-forneria", "_blank");
    }
  };

  const openSheet = () => {
    navigation("/" + location?.search);
  };

  return (
    <>
      <Styles.PopUp>
        <CustomText
          style={{ marginRight: "20px" }}
          textColor="white"
          fontSize={2}
          fontFamily={fonts.medium}
        >
          <span
            style={{
              fontFamily: fonts.bold,
              fontSize: Spacing(2),
            }}
          >
            {data?.name ? data?.name : "..."}
          </span>
          , finalize a conclusão da sua reserva com o envio de seus dados no
          botão ao lado!
        </CustomText>
        <Styles.CheckInButton
          onClick={openSheet}
          endIcon={<ChevronRight />}
          fullWidth={false}
        >
          Concluir Reserva
        </Styles.CheckInButton>
      </Styles.PopUp>

      <Styles.PopUpMob>
        <CustomText
          style={{ marginRight: "20px" }}
          textColor="white"
          fontSize={2.25}
          fontFamily={fonts.bold}
        >
          Conclua sua Reserva
        </CustomText>
        <Styles.CheckInButton
          onClick={openSheet}
          endIcon={<ChevronRight />}
          fullWidth={false}
        >
          Enviar Dados
        </Styles.CheckInButton>
      </Styles.PopUpMob>

      <Styles.PageBody>
        <Styles.Container>
          <Styles.PageHeader>
            <Styles.BlockHeader>
              <SVG.Behome
                style={{
                  width: "180px",
                  height: "fit-content",

                  fill: "white",
                  alignSelf: "flex-end",
                }}
              />
              <div>
                <CustomText
                  style={{ display: "block" }}
                  textColor="white"
                  fontSize={3}
                  fontFamily={fonts.bold}
                >
                  Olá,
                  <span
                    style={{
                      fontFamily: fonts.extrabold,
                      fontSize: Spacing(4),
                    }}
                  >
                    ‎ {data?.name ? data?.name : "..."}
                  </span>
                </CustomText>
                <CustomText
                  textColor="white"
                  fontSize={2.5}
                  fontFamily={fonts.medium}
                >
                  Fique por dentro das principais atrações de Florianópolis!
                </CustomText>
              </div>
            </Styles.BlockHeader>
            <Styles.ShadowHeader></Styles.ShadowHeader>
          </Styles.PageHeader>

          <Styles.PaddingContainer>
            <Styles.BlockContainer>
              <CustomText
                fontFamily={fonts.extrabold}
                fontSize={3}
                textColor="#00796D"
              >
                <SVG.Brackeys
                  style={{
                    fill: "#00796D",
                    maxHeight: "30px",
                    paddingRight: "6px",
                  }}
                />{" "}
                O que fazer em Florianópolis
              </CustomText>

              <Grid container spacing={1}>
                {pics.map((item, index) => (
                  <Grid item xs={6} sm={3} md={3} lg={3} xl={3} key={index}>
                    <div
                      onClick={openPics}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={item}
                        alt="Descrição"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Styles.BlockContainer>

            <Styles.BlockContainer>
              <CustomText
                fontFamily={fonts.extrabold}
                fontSize={3}
                textColor="#00796D"
              >
                <SVG.Brackeys
                  style={{
                    fill: "#00796D",
                    maxHeight: "30px",
                    paddingRight: "6px",
                  }}
                />{" "}
                Acompanhe a Bewiki no Instagram
              </CustomText>

              <Grid container spacing={1}>
                {picsInsta.map((item, index) => (
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                    <div
                      onClick={openInsta}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={item}
                        alt="Descrição"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Styles.BlockContainer>

            <Styles.BlockContainer>
              <div>
                <CustomText
                  fontFamily={fonts.extrabold}
                  fontSize={3}
                  textColor="black"
                  style={{ paddingBottom: "5px" }}
                >
                  <SVG.Brackeys
                    style={{
                      fill: "black",
                      maxHeight: "30px",
                      paddingRight: "6px",
                    }}
                  />{" "}
                  Bewiki Market
                </CustomText>
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={2.5}
                  textColor="black"
                >
                  Explore nossos deliciosos restaurantes parceiros localizados
                  no nosso Hall de entrada:
                </CustomText>
              </div>

              <Grid container spacing={2}>
                {picsMarket.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                    <Styles.CardMarket
                      onClick={() => openMarketFood(index)}
                      $color={item.color}
                    >
                      <CustomText
                        textColor={item.textColor}
                        fontFamily={fonts.bold}
                        fontSize={2}
                      >
                        {item.label}
                      </CustomText>
                      <CustomText
                        textColor={item.textColor}
                        fontFamily={fonts.medium}
                        fontSize={1.75}
                      >
                        {item.subText}
                      </CustomText>
                      <Styles.ImgMarket src={item.img} alt="Descrição" />
                    </Styles.CardMarket>
                  </Grid>
                ))}
              </Grid>
            </Styles.BlockContainer>

            <Styles.BlockContainer alternative={1}>
              <div>
                <CustomText
                  fontFamily={fonts.extrabold}
                  fontSize={3}
                  textColor="white"
                  style={{ paddingBottom: "5px" }}
                >
                  <SVG.Brackeys
                    style={{
                      fill: "white",
                      maxHeight: "30px",
                      paddingRight: "6px",
                    }}
                  />{" "}
                  Informações úteis
                </CustomText>
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={2.5}
                  textColor="white"
                >
                  Dúvidas sobre logística e mobilidade para facilitar sua
                  estadia na Ilha
                </CustomText>
              </div>

              <Grid container spacing={2}>
                {infos.map((item, index) => (
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                    <Styles.ButtonInfo onClick={item.onClick}>
                      <Icon color="#00796D" path={item.icon} size={1.5} />
                      <CustomText
                        textColor="#000000"
                        fontFamily={fonts.bold}
                        fontSize={2.5}
                      >
                        {item.label}
                      </CustomText>
                    </Styles.ButtonInfo>
                  </Grid>
                ))}
              </Grid>
            </Styles.BlockContainer>

            <Styles.BlockContainer>
              <CustomText
                fontFamily={fonts.extrabold}
                fontSize={3}
                textColor="black"
                style={{ paddingBottom: "5px" }}
              >
                <SVG.Brackeys
                  style={{
                    fill: "black",
                    maxHeight: "30px",
                    paddingRight: "6px",
                  }}
                />{" "}
                Quer fazer um update na sua reserva?
              </CustomText>

              <Grid container spacing={2}>
                {whats.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <div>
                      <div>
                        <div
                          style={{
                            padding: "14px",
                            backgroundColor: "#00796D",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CustomText
                            textColor="white"
                            fontSize={2.25}
                            fontFamily={fonts.bold}
                          >
                            {item.label}
                          </CustomText>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            maxHeight: "170px",
                            position: "relative",

                            height: "100vh",

                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${item.img})`,
                          }}
                        >
                          <Styles.ShadowHeader></Styles.ShadowHeader>
                        </div>

                        <div
                          style={{
                            padding: "18px",
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CustomText fontSize={2} fontFamily={fonts.medium}>
                            {item.subText}
                          </CustomText>
                          <Styles.ButtonZap
                            onClick={item.onClick}
                            endIcon={<ChevronRight />}
                            fullWidth={false}
                          >
                            Whatsapp
                          </Styles.ButtonZap>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Styles.BlockContainer>

            <Styles.BlockContainer alternative={2}>
              <img
                src={BeStore}
                style={{ maxWidth: "207px", paddingBottom: "10px" }}
              />
              <div>
                <CustomText
                  fontFamily={fonts.extrabold}
                  fontSize={3}
                  textColor="black"
                  style={{ paddingBottom: "5px" }}
                >
                  <SVG.Brackeys
                    style={{
                      fill: "black",
                      maxHeight: "30px",
                      paddingRight: "6px",
                    }}
                  />{" "}
                  Leve um pedaço da Bewiki com você
                </CustomText>
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={2}
                  textColor="black"
                >
                  Confira nossa loja oficial dos itens e utensílios Bewiki!
                </CustomText>
              </div>
              <Styles.ButtonZap
                onClick={openMarket}
                alternative
                fullWidth={false}
              >
                Acessar
              </Styles.ButtonZap>
            </Styles.BlockContainer>
          </Styles.PaddingContainer>
        </Styles.Container>
      </Styles.PageBody>

      <Styles.Footer>
        <Styles.LeftFooter>
          <SVG.Bewiki
            style={{ fill: "white", width: "147.43px", height: "fit-content" }}
          />
          <CustomText
            style={{ gap: "4px", alignItems: "center" }}
            textColor="white"
            fontSize={2}
            fontFamily={fonts.medium}
          >
            <CopyrightIcon />
            Bewiki 2021 - 2024
          </CustomText>
        </Styles.LeftFooter>
        <Styles.RightFooter>
          <IconButton
            style={{ width: "fit-content", padding: "8px" }}
            color="secondary"
            onClick={() => {}}
          >
            <InstagramIcon style={{ fontSize: "32px" }} />
          </IconButton>
          <IconButton
            style={{ width: "fit-content", padding: "8px" }}
            color="secondary"
            onClick={() => {}}
          >
            <LinkedIn style={{ fontSize: "32px" }} />
          </IconButton>
          <IconButton
            style={{ width: "fit-content", padding: "8px" }}
            color="secondary"
            onClick={() => {}}
          >
            <Facebook style={{ fontSize: "32px" }} />
          </IconButton>
        </Styles.RightFooter>
      </Styles.Footer>
    </>
  );
}

export default Landing;
